import React, { FC, SyntheticEvent, useState } from 'react';

import { Input, LoadingOverlay, PhoneInput, PrimaryButton } from 'components';
import { ChevronRightIcon } from 'shared/icons';
import styles from './LoyaltyJoinForm.module.scss';

import { ErrorMessages } from 'shared/constants/errorMessages';
import {
    CustomerStatus,
    TCustomerStatus,
} from 'shared/api/v2/customers/customers.types';
import {
    createCustomer,
    getCustomerStatus,
    joinCustomer,
    sendPhoneVerification,
    updateCustomer,
} from 'shared/api/v2/customers/customers.utils';
import { TSourceKey, TUTMMediumKey } from 'shared/types';
import { resolveCustomerStatus } from './utils';
import { TVerificationModalError } from '../VerificationCodeModal/VerificationCodeModal';
import { formatPhoneNumber } from 'react-phone-number-input';

export interface ILoyaltyJoinFormProps {
    customerEmail: string;
    customerPhoneNumber: string;
    setCustomerEmail: (email: string) => void;
    setCustomerPhoneNumber: (phoneNumber: string) => void;
    isVerificationModalOpen: boolean;
    setIsVerificationModalOpen: (
        isOpen: boolean,
        shouldShowCustomerModalAfterClose?: boolean,
        errorMessage?: TVerificationModalError
    ) => void;
    merchantId: string;
    merchantPhoneNumber: string | null;
    source: TSourceKey;
    medium?: TUTMMediumKey;
    isRootMerchant: boolean;
    setIsThankYouOpen: (isOpen: boolean) => void;
    setIsCustomerModalOpen: (isOpen: boolean) => void;
    setCustomerId: (customerId: string | null) => void;
    setCustomerStatus: (status: TCustomerStatus) => void;
}

const LoyaltyJoinForm: FC<ILoyaltyJoinFormProps> = ({
    customerEmail,
    customerPhoneNumber,
    isRootMerchant,
    merchantId,
    merchantPhoneNumber,
    setIsVerificationModalOpen,
    setCustomerEmail,
    setCustomerPhoneNumber,
    setCustomerId,
    setCustomerStatus,
    setIsCustomerModalOpen,
    source,
    medium,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [fieldError, setFieldError] = useState({ email: '', phone: '' });

    if (!merchantId) {
        return null;
    }

    const onEmailChange = (value: string): void => {
        if (fieldError.email) {
            setFieldError((s) => ({ ...s, email: '' }));
        }
        setCustomerEmail(value);
    };

    const onPhoneNumberChange = (value: string): void => {
        if (fieldError.phone) {
            setFieldError((s) => ({ ...s, phone: '' }));
        }
        setCustomerPhoneNumber(value);
    };

    const onClear = (): void => {
        setCustomerEmail('');
    };

    function handleRootMerchantStatus(
        status: TCustomerStatus,
        customerId?: string | null,
        foundCustomerIdByPhoneNumber?: string | null
    ) {
        if (status === CustomerStatus.CONNECTED) {
            const alreadyConnected =
                customerId === foundCustomerIdByPhoneNumber ? 'phone' : 'email';
            setFieldError((s) => ({
                ...s,
                [alreadyConnected]: `This ${alreadyConnected} is already part of our loyalty program`,
            }));
        }
        if (
            status === CustomerStatus.NOT_FOUND ||
            status === CustomerStatus.NOT_CONNECTED
        ) {
            setIsCustomerModalOpen(true);
        }
    }

    const createNewCustomer = async () => {
        const { data: newCustomer, error } = await createCustomer({
            email: customerEmail.length ? customerEmail : undefined,
            merchant_id: merchantId,
            source,
            medium,
            customer_info: { phone_number: customerPhoneNumber },
        });
        if (error || !newCustomer) {
            if (error?.phone_number) {
                setFieldError((s) => ({ ...s, phone: error.phone_number }));
            }
            if (error?.email) {
                setFieldError((s) => ({ ...s, email: error.email }));
            }
            if (error?.generic) {
                setError(error?.generic);
            }
            return false;
        }
        setCustomerId(newCustomer.customer_id);
        setCustomerStatus('connected');
        return true;
    };

    const handlePhoneVerification = async (
        shouldShowCustomerModalAfterClose: boolean
    ) => {
        const data = await sendPhoneVerification(customerPhoneNumber);
        if (data.error && data.error === 'phone_number_has_unsubscribed') {
            const error: TVerificationModalError = {
                type: 'general',
                message:
                    'Text START to {XXX} to resubscribe to messages at any time.',
            };
            if (merchantPhoneNumber) {
                error.message = error.message.replace(
                    '{XXX}',
                    formatPhoneNumber(merchantPhoneNumber)
                );
            } else {
                error.type = 'merchant_phone';
                error.message =
                    'Oops! Something went wrong with your verification code. Please try again.';
            }

            setIsVerificationModalOpen(
                true,
                shouldShowCustomerModalAfterClose,
                error
            );
        } else {
            setIsVerificationModalOpen(true, shouldShowCustomerModalAfterClose);
        }
    };

    const joinExistingCustomer = async (
        customerId: string,
        shouldUpdate: 'phone' | 'email' | null
    ) => {
        const { error } = await joinCustomer(customerId, {
            merchant_id: merchantId,
            source,
            medium,
        });

        if (error) {
            setError(error || ErrorMessages.DEFAULT);
            return false;
        }

        const updatePayload: Record<string, string> = {};
        if (shouldUpdate === 'phone') {
            updatePayload.phone_number = customerPhoneNumber;
        } else if (shouldUpdate === 'email') {
            updatePayload.email = customerEmail;
        }

        if (shouldUpdate) {
            await updateCustomer(customerId, updatePayload);
        }

        return true;
    };

    const fetchCustomerStatus = async (identifier: {
        email?: string;
        phone_number?: string;
    }) => {
        const { data: customerInfo, error } = await getCustomerStatus({
            ...identifier,
            merchantId,
        });
        if (customerInfo) return customerInfo;
        if (error) {
            setError(error?.generic || '');
            setFieldError({
                email: error?.email || '',
                phone: error?.phone_number || '',
            });
        }
        throw new Error('Customer fetch failed');
    };

    const onSubmit = async (evt: SyntheticEvent<HTMLFormElement>) => {
        evt.preventDefault();
        setIsLoading(true);
        setError('');
        setFieldError({ email: '', phone: '' });

        try {
            // [CS-899] We updated email to be required. The logic we use below assumes email or phone are required
            // which is why we check the status for each separately, now that they are both required we can invoke the status check once
            // @TODO: consolidate the two requests, call fetchCustomerStatus once for phone and email
            const customerByEmail = customerEmail
                ? await fetchCustomerStatus({ email: customerEmail })
                : null;
            const customerByPhoneNumber = customerPhoneNumber
                ? await fetchCustomerStatus({
                      phone_number: customerPhoneNumber,
                  })
                : null;

            const { customerStatus, customerId, shouldUpdate } =
                resolveCustomerStatus(customerByPhoneNumber, customerByEmail);

            setCustomerId(customerId);
            setCustomerStatus(customerStatus);

            if (isRootMerchant) {
                if (customerStatus === CustomerStatus.CONNECTED) {
                    const alreadyConnected =
                        customerId === customerByPhoneNumber?.customer_id
                            ? 'phone'
                            : 'email';
                    setFieldError((s) => ({
                        ...s,
                        [alreadyConnected]: `This ${alreadyConnected} is already part of our loyalty program`,
                    }));
                }
                handleRootMerchantStatus(
                    customerStatus,
                    customerId,
                    customerByPhoneNumber?.customer_id
                );
                return;
            }

            switch (customerStatus) {
                case CustomerStatus.CONNECTED:
                    const alreadyConnected =
                        customerId === customerByPhoneNumber?.customer_id
                            ? 'phone'
                            : 'email';
                    setFieldError((s) => ({
                        ...s,
                        [alreadyConnected]: `This ${alreadyConnected} is already part of our loyalty program`,
                    }));
                    break;
                case CustomerStatus.NOT_FOUND:
                    if (await createNewCustomer()) {
                        await handlePhoneVerification(true);
                    }

                    break;
                case CustomerStatus.NOT_CONNECTED:
                    if (customerId) {
                        await joinExistingCustomer(customerId, shouldUpdate);
                        await handlePhoneVerification(false);
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error);
            setError(ErrorMessages.DEFAULT);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={onSubmit} className={styles.Form}>
            <PhoneInput
                value={customerPhoneNumber}
                onChange={onPhoneNumberChange}
                label="Phone number"
                required
                errorMessage={fieldError.phone}
            />
            <Input
                hasIcon
                value={customerEmail}
                onChange={onEmailChange}
                onClear={onClear}
                type="email"
                label="Email"
                required
                placeholder="ex: john.smith@email.com"
                className={styles.Input}
                errorMessage={fieldError.email}
            />
            <PrimaryButton
                disabled={!customerPhoneNumber || !customerEmail || isLoading}
                type="submit"
                icon={<ChevronRightIcon />}
                className={styles.SubmitButton}
            >
                Sign up
            </PrimaryButton>
            {error && <p className={styles.Error}>{error}</p>}
            {isLoading && <LoadingOverlay />}
        </form>
    );
};

export default LoyaltyJoinForm;
